<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="AWS"
      :properties="properties"
      step-label="cloudfront"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedCloudFrontActions"
        :default-value="properties.action"
        step-label="cloudfront"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col
      v-if="properties.action === 'CREATE'"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-domainName`"
        v-model="properties.fields.domainName"
        outlined
        dense
        :label="$lang.labels.domainName"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
      <v-text-field
        :key="`${step.id}-domainId`"
        v-model="properties.fields.domainId"
        outlined
        dense
        :label="$lang.labels.domainId"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    
    <v-col v-if="['DELETE', 'UPDATE'].includes(properties.action)" cols="12">
      <v-text-field
        :key="`${step.id}-distributionId`"
        v-model="properties.fields.distributionId"
        outlined
        dense
        required
        :label="$lang.labels.distributionId"
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>

    <v-col v-if="['CREATE', 'UPDATE'].includes(properties.action)" cols="12">
      <v-text-field
        :key="`${step.id}-distributionComment`"
        v-model="properties.fields.distributionComment"
        outlined
        dense
        :label="$lang.labels.distributionComment"
        :readonly="!canEdit"
      />
    </v-col>

    <v-col v-if="properties.action !== 'DELETE'" cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.fields.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  ActionSelect,
  CredentialsSelect
} from './components'
import StepMixin from './StepMixin'

import { CLOUDFRONT_ACTIONS } from './constants'

export default {
  name: 'CloudFrontStep',
  components: {
    ActionSelect,
    CredentialsSelect
  },
  mixins: [StepMixin],
  computed: {
    formattedCloudFrontActions() {
      return CLOUDFRONT_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.fields) this.$set(this.properties, 'fields', {})
  }
}
</script>
